import { ProductCard } from "./ProductCard";

export const ProductDetails = ({products, productIndex, productGroup, groupName}) => {
    const selectedProduct = productGroup[productIndex]

    const filteredProductGroup = products.find(group => group.groupName.toLowerCase() === groupName.toLowerCase())?.products;

    const filteredProduct = filteredProductGroup.find(product => product.productName.toLowerCase() === selectedProduct.title.toLowerCase());

    return (
        <div className="w-full h-auto bg-gray-200 rounded-3xl">
            <ProductCard key={productIndex} title={filteredProduct.productName} details={filteredProduct.productDetails} />
        </div>
    )
}