import { Link } from 'react-router-dom';

export default function Productmenu() {
	return (
		<div className='absolute left-0 ml-5 space-y-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg'>
			<ul className="font-TT Commons float-right">
				<li className="px-[2rem] py-2 hover:bg-gray-100 hover:text-light-orange text-deep-blue text-base font-semibold">
					<Link  to="/products/spices">
						Spices
					</Link>
				</li>
				<li className="px-[2rem] py-2 hover:bg-gray-100 hover:text-light-orange text-deep-blue text-base font-semibold">
					<Link  to="/products/nuts">
						Nuts
					</Link>
				</li>
				<li className="px-[2rem] py-2 hover:bg-gray-100 hover:text-light-orange text-deep-blue text-base font-semibold">
					<Link  to="/products/herbs">
						Herbs
					</Link>
				</li>
				<li className="px-[2rem] py-2 hover:bg-gray-100 hover:text-light-orange text-deep-blue text-base font-semibold">
					<Link  to="/products/seeds">
						Seeds
					</Link>
				</li>
				<li className="px-[2rem] py-2 hover:bg-gray-100 hover:text-light-orange text-deep-blue text-base font-semibold">
					<Link  to="/products/charcoal">
						Charcoal
					</Link>
				</li>
		</ul>
		</div>
	);
}
