import { useEffect, useState } from "react";
import { ProductHero } from "./ProductHero";
import { ProductImage } from "./ProductImage";
import { ProductDetails } from "./ProductDetails";
import Footer from "../footer/Footer";
import { AboutProduct } from "./AboutProduct";
import NavBar from "../navbar/Navbar";

export const ProductGroup = ({ groupName, groupData, products }) => {
  const [index, setIndex] = useState(0);
  const [productIndex, setProductIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((oldIndex) => (oldIndex + 1) % groupData.length);
    }, 5000);

    return () => clearInterval(intervalId); //cleanup on unmount
  }, [groupData]);

  const handleImageChange = (index) => {
    console.log(`Selected group index: ${index}`);
    setProductIndex(index);
  };

  return (
    <>
      <div className="sm:mx-12 mx-6 w-auto mt-4 overflow-hidden">
        <NavBar />
        <div className="mt-[6.5rem]">
          <ProductHero photo={groupData[index].photo} text1={groupName} />
        </div>
        <section className="grid lg:grid-cols-2 lg:my-16 my-1">
          <div className="sm:w-[60%] w-52 lg:h-[650px] h-[350px] flex items-center justify-center mx-auto">
            <ProductImage
              productGroup={groupData}
              onImageChange={handleImageChange}
            />
          </div>
          <div className="w-auto flex items-center justify-center mx-auto lg:mt-0 sm:mt-10 -mt-24">
            <ProductDetails
              productGroup={groupData}
              productIndex={productIndex}
              groupName={groupName}
              products={products}
            />
          </div>
        </section>
        <section className="my-10">
          <AboutProduct productGroup={groupData} productIndex={productIndex} groupName={groupName} products={products} />
        </section>
      </div>
      <Footer />
    </>
  );
};
