import { Link } from 'react-router-dom';
import { Arrow } from '../../assets/svg/icons';
import { GoodService, ProductsGrid } from '../images/images';

export default function Service() {
	return (
		<div  className='sm:mb-10'>
			<section className="grid lg:grid-cols-3">
				<div className='lg:col-span-1'>
					<p className="lg:mt-32 lg:text-start text-center font-semibold text-4xl mb-8 text-[#02338f]">
						We offer premium services.
					</p>
					<p className="text-sm lg:w-[25rem] w-full lg:mx-0 mx-auto lg:text-start text-center">
						We do not only sell and distribute products, we also offer services to your satisfaction as  we have your interest at heart. We render services like customized packaging, processing of quality products and other services.
					</p>
					<Link to='/about' className='p-5 lg:mx-0 lg:text-start text-center flex justify-center items-center'>
						<div className='sm:flex'>
							<p className="font-semibold text-sm text-[rgba(33, 33, 33, 0.72)]">LEARN MORE</p>

							<div className="hidden sm:block mt-[0.35rem] ml-2">
								<Arrow />
							</div>
						</div>
					</Link>
				</div>

				<div className="lg:col-span-2 relative group w-full lg:h-[750px] sm:h-[630px] h-[430px]">
					<div className='absolute top-0 right-0 sm:w-[75%] w-[85%] lg:h-[730px] sm:h-[600px] h-[400px] rounded-s-[3.5rem] overflow-hidden flex items-center justify-center'>
						<img 
							src={ProductsGrid} 
							alt="Main" 
							className="h-full w-full "
						/>
					</div>
					<div className='absolute lg:bottom-0 top-0 left-0 sm:w-[320px] w-44 lg:h-[300px] sm:h-[200px] h-40 rounded-[3.5rem] border-white border-[1.1rem] 2xl:ml-32 xl:ml-8 lg:-ml-16 -ml-2 sm:mt-[23rem] mt-52 overflow-hidden flex items-center'>
						<img 
							src={GoodService} 
							alt="Overlay" 
							className="w-full"
						/>
					</div>
				</div>
			</section>
			{/* <hr className="mt-[2rem]" /> */}
		</div>
	);
}
