import React from 'react'
import { CountrySelection, Input, Message, Select } from './Input'
import { Bags } from '../images/images'

const contactDetails = [
    {type: 'text', name: 'fullName', id: 'fullName', label: 'full name', holder: 'John Doe'},
    {type: 'email', name: 'email', id: 'email', label: 'email address', holder: 'john_doe@email.com'},
    {type: 'text', name: 'phone', id: 'phone', label: 'phone number', holder: '+234800000000'},
    {type: 'text', name: 'company', id: 'company', label: 'Company', holder: 'John Doe Enterprises'}
]

export const ContactForm = () => {
  return (
    <div className='grid grid-cols-1 sm:mt-[6.5rem] mt-14 mb-10 lg:py-20 py-10 bg-cover bg-no-repeat bg-center rounded' style={{backgroundImage: `url(${Bags})`}}>
      <h3 className='text-center text-white font-bold md:text-5xl text-4xl lg:w-1/2 w-3/4 mx-auto mb-5'>Want to get in touch with us?</h3>
      <p className='text-center text-white font-medium text-lg lg:w-1/2 w-3/4 mx-auto mb-10'>You can send us a mail here by filling the form and we would get in touch.</p>
        <form action="" className='bg-white/35 backdrop-blur-sm border-2 border-gray-400 md:px-20 sm:px-10 px-5 rounded-2xl lg:w-1/2 w-3/4 mx-auto'>
            {contactDetails.map((details, index) => (
                <Input key={index} type={details.type} name={details.name} id={details.id} labelText={details.label} holder={details.holder} />
            ))}
            <CountrySelection />
            <Select />
            <Message label='Message' holder='Your message to us...'/>
            <button type='submit' className='bg-light-blue hover:bg-deep-blue text-white font-bold text-base w-36 py-2 block mx-auto my-10 rounded-xl'>SEND</button>
        </form>
    </div>
  )
}
