// import { Link } from 'react-router-dom';
// import { Dropdown } from '../../assets/svg/icons';
// import Logo from '../../assets/logos/iphy-logo-full.png';
// import List from '../home/HomeNavList';
// import Productmenu from '../product/ProductMenu';
// import { useState } from 'react';
// import { FaGripLines, FaX } from 'react-icons/fa6';
// import LanguageDropdown from '../navbar/Language';


// const SideBar = ({isOpen}) => {
// 	return (
// 		<div className={`xl:hidden bg-[rgba(44,42,42,0.3)] mt-24 fixed h-full inset-0 z-20 ${isOpen ? 'block' : 'hidden'}`}>
// 			<ul className='p-4 md:w-1/4 w-1/2 h-full bg-white opacity-100'>
// 					<div className='mb-4'>
// 						<Link to="/">
// 							<List title="Home" />
// 						</Link>
// 					</div>
// 					<div className='mb-4'>
// 						<Link to="/about">
// 							<List title="About Us " />
// 						</Link>
// 					</div>
// 					<div  className="mb-4">
// 						<div className='flex items-center mb-4'>
// 							<List title="Our Products" />
// 							<div className="relative top-[2px] right-[25px]">
// 								<Dropdown />
// 							</div>
// 						</div>
// 						<ul className='ml-6'>
// 							<div className='mb-4'>
// 								<Link to='/products/spices'>
// 									<List title='Spices' />
// 								</Link>
// 							</div>
// 							<div className='mb-4'>
// 								<Link to='/products/nuts'>
// 									<List title='Nuts' />
// 								</Link>
// 							</div>
// 							<div className='mb-4'>
// 								<Link to='/products/herbs'>
// 									<List title='Herbs' />
// 								</Link>
// 							</div>
// 							<div className='mb-4'>
// 								<Link to='/products/seeds'>
// 									<List title='Seeds' />
// 								</Link>
// 							</div>
// 							<div className='mb-4'>
// 								<Link to='/products/charcoal'>
// 									<List title='Charcoal' />
// 								</Link>
// 							</div>
// 						</ul>
// 					</div>
// 					<div className='mb-4'>
// 						<Link to="/faqs">
// 							<List title="FAQs" />
// 						</Link>
// 					</div>
// 					<div className='mb-4'>
// 						<Link to="/contact-us">
// 							<List title="Contact Us" />
// 						</Link>
// 					</div>
// 				</ul>
// 		</div>
// 	)
// }

// export default function FaQsNav({ text1, text2, onClick }) {
// 	const [ isDropdownOpen, setDropDownOpen] = useState(false);
// 	const [sidebar, setSidebar] = useState(false)

// 	const toggleSidebar = () => {
// 		setSidebar(!sidebar);
// 	}
// 	return (
// 		< >

// 			<nav className="flex justify-between items-center text-sm font-medium fixed top-0 left-0 right-0 bg-white z-10 border-b border-gray-300 shadow-lg">
// 				<div className='flex xl:justify-normal justify-between items-center xl:w-8/12'>
// 					<div className='lg:ml-12 xl:hidden'>
// 						<button onClick={toggleSidebar} className='xl:hidden p-2' >{sidebar ? <FaX size={24} /> : <FaGripLines size={24} />}</button>
// 					</div>
// 					<div className="w-24 xl:mx-12">
// 						<Link to="/">
// 							<img src={Logo} alt="Logo" height="5"/>
// 						</Link>
// 					</div>
// 					<ul className={`xl:flex p-0 hidden`}>
// 						<Link to="/">
// 							<List title="Home" />
// 						</Link>
// 						<Link to="/about">
// 							<List title="About Us " />
// 						</Link>
// 						<div onMouseEnter={() => setDropDownOpen(true)} onMouseLeave={() => setDropDownOpen(false)} className="relative">
// 							<div className='flex items-center'>
// 								<List title="Our Products" />
// 								<div className="relative top-[2px] right-[25px]">
// 									<Dropdown />
// 								</div>
// 							</div>
// 							{isDropdownOpen &&  (
// 								<Productmenu />
// 							)}
// 						</div>
// 						<Link to="/faqs">
// 							<List title="FAQs" />
// 						</Link>
// 						<Link to="/contact-us">
// 							<List title="Contact Us" />
// 						</Link>
// 					</ul>
// 					<SideBar isOpen={sidebar} />
// 				</div>
// 				<div className='flex p-0'>
// 					<Link to="/">
// 						<button className="w-32 h-10 border-solid border-gray-100 rounded-lg p-2  text-white text-base font-bold" style={{backgroundColor: '#0145ca', transition: "background-color 0.3s ease"}} onMouseOver={(e) => {e.target.style.backgroundColor = "#02338f"}} onMouseOut={(e) => (e.target.style.backgroundColor = '#0145ca')}>
// 							Let's talk
// 						</button>
// 					</Link>
// 					<LanguageDropdown />
// 				</div>
// 			</nav>
// 			<main className="-mt-[45%] text-white mx-[29rem]">
// 				<p className="font-bold text-5xl leading-snug pt-40 w-[23rem] relative z-10">{text1}</p>
// 				<p className="w-[73%] pt-8 mx-[2rem]">{text2}</p>
// 			</main>
// 		</>
// 	);
// }



import { Link } from 'react-router-dom';
import { Dropdown } from '../../assets/svg/icons';
import Logo from '../../assets/logos/iphy-logo-full.png';
import List from '../home/HomeNavList';
import Productmenu from '../product/ProductMenu';
import { useState } from 'react';
import { FaGripLines, FaX } from 'react-icons/fa6';
import LanguageDropdown from '../navbar/Language';

const SideBar = ({ isOpen }) => {
  return (
    <div className={`xl:hidden bg-[rgba(44,42,42,0.3)] mt-24 fixed h-full inset-0 z-20 ${isOpen ? 'block' : 'hidden'}`}>
      <ul className="p-4 md:w-1/4 w-1/2 h-full bg-white opacity-100">
        <div className="mb-4">
          <Link to="/">
            <List title="Home" />
          </Link>
        </div>
        <div className="mb-4">
          <Link to="/about">
            <List title="About Us " />
          </Link>
        </div>
        <div className="mb-4">
          <div className="flex items-center mb-4">
            <List title="Our Products" />
            <div className="relative top-[2px] right-[25px]">
              <Dropdown />
            </div>
          </div>
          <ul className="ml-6">
            <div className="mb-4">
              <Link to="/products/spices">
                <List title="Spices" />
              </Link>
            </div>
            <div className="mb-4">
              <Link to="/products/nuts">
                <List title="Nuts" />
              </Link>
            </div>
            <div className="mb-4">
              <Link to="/products/herbs">
                <List title="Herbs" />
              </Link>
            </div>
            <div className="mb-4">
              <Link to="/products/seeds">
                <List title="Seeds" />
              </Link>
            </div>
            <div className="mb-4">
              <Link to="/products/charcoal">
                <List title="Charcoal" />
              </Link>
            </div>
          </ul>
        </div>
        <div className="mb-4">
          <Link to="/faqs">
            <List title="FAQs" />
          </Link>
        </div>
        <div className="mb-4">
          <Link to="/contact-us">
            <List title="Contact Us" />
          </Link>
        </div>
      </ul>
    </div>
  );
};

export default function FaQsNav({ text1, text2, onClick }) {
  const [isDropdownOpen, setDropDownOpen] = useState(false);
  const [sidebar, setSidebar] = useState(false);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  return (
    <>
      <nav className="flex justify-between items-center text-sm font-medium fixed top-0 left-0 right-0 bg-white z-10 border-b border-gray-300 shadow-lg">
        <div className="flex xl:justify-normal justify-between items-center xl:w-8/12">
          <div className="lg:ml-12 xl:hidden">
            <button onClick={toggleSidebar} className="xl:hidden p-2">
              {sidebar ? <FaX size={24} /> : <FaGripLines size={24} />}
            </button>
          </div>
          <div className="w-24 xl:mx-12">
            <Link to="/">
              <img src={Logo} alt="Logo" height="5" />
            </Link>
          </div>
          <ul className={`xl:flex p-0 hidden`}>
            <Link to="/">
              <List title="Home" />
            </Link>
            <Link to="/about">
              <List title="About Us " />
            </Link>
            <div onMouseEnter={() => setDropDownOpen(true)} onMouseLeave={() => setDropDownOpen(false)} className="relative">
              <div className="flex items-center">
                <List title="Our Products" />
                <div className="relative top-[2px] right-[25px]">
                  <Dropdown />
                </div>
              </div>
              {isDropdownOpen && <Productmenu />}
            </div>
            <Link to="/faqs">
              <List title="FAQs" />
            </Link>
            <Link to="/contact-us">
              <List title="Contact Us" />
            </Link>
          </ul>
          <SideBar isOpen={sidebar} />
        </div>
        <div className="flex p-0">
          <Link to="/">
            <button
              className="w-32 h-10 border-solid border-gray-100 rounded-lg p-2 text-white text-base font-bold"
              style={{ backgroundColor: '#0145ca', transition: 'background-color 0.3s ease' }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = '#02338f';
              }}
              onMouseOut={(e) => (e.target.style.backgroundColor = '#0145ca')}
            >
              Let's talk
            </button>
          </Link>
          <LanguageDropdown />
        </div>
      </nav>
			<main className="-mt-[40%] text-white mx-[29rem] ">
				<p className="font-bold text-5xl leading-snug pt-28 w-[23rem] relative  z-10 ">{text1}</p>
				<p className="w-[73%] pt-8 mx-[2rem]">{text2}</p>
			</main>
    </>
  );
}