import { FaBuildingColumns, FaHandHoldingDollar, FaTruckFast, FaWhatsapp } from "react-icons/fa6";
import WhyUsList from '../about/WhyUsList';
import { whyUs } from "../images/images";
import { Link } from "react-router-dom";

const iconStyle = {
	color: "#ec911e",
	fontSize: "24px"
}

const iconWA = {
	color: "#ec911e"
}

export default function WhyUs() {
	return (
		<section className="flex lg:flex-nowrap flex-wrap items-start justify-between mt-[3rem]">
			<div>
				<h3 className="font-semibold text-4xl w-full text-deep-blue">
					Why you should order from us<br className="xl:block hidden" /> at IPHECHO?
				</h3>
				<div>
					<img src={whyUs} alt="" className="relative lg:mt-20 mt-10 rounded-3xl lg:h-[35rem] sm:w-[85%] w-[90%]" />
					<div className="bg-white sm:p-4 p-2 rounded-2xl flex items-start absolute sm:-mt-[6.5rem] -mt-16 lg:left-[20rem] md:left-[28rem] sm:left-[23rem] right-16 sm:h-20 h-16 sm:w-[16rem] w-[13rem]">
						<div className="bg-gray-100 sm:text-center rounded-full sm:w-[55px] w-[35px] sm:p-[10px] p-1">
							<FaWhatsapp style={iconWA} className="sm:text-[38px] text-[25px]" />
						</div>
						<div className="sm:ml-4 ml-2 drop-shadow-2xl">
							<p className="text-sm text-deep-blue">Get in touch with us</p>
							<Link to="https://wa.me/+2348065680616" className="font-bold text-base">+2348065680616</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="lg:w-1/2 w-full  text-[#888888] lg:mt-0 mt-20">
				<p className="font-medium text-sm w-full">
				Our commitment to quality differentiates Iphecho as your top choice for purchasing agricultural goods. With a strong dedication to quality, dependability, and sustainability, we go above and beyond to meet and exceed your expectations. Discover the difference with Iphecho and join our growing list of satisfied clients and experience the unrivaled quality, dependability, and value we provide. Place your order today and let us exceed your expectations. Among others, we offer:
				</p>
				<ul className="xl:mt-16 mt-10">
					<WhyUsList
						icon={<FaBuildingColumns style={iconStyle} />}
						header={'Premium Quality'}
						text="At Iphecho, we are dedicated to providing items of superior quality. From start to finish, we ensure excellence at all stages so that each product preserves its outstanding flavor, freshness, and nutritional content. Trust us to provide you with the best assortment of quality items, properly put together and carefully selected for your satisfaction."
					/>
					<WhyUsList
						icon={<FaTruckFast style={iconStyle} />}
						header={'Swift Delivery'}
						text="Time is of the essence, and we at Iphecho recognize the value of prompt delivery. Our simplified transportation system and key partnerships allow us to accurately complete and speed up your deliveries. Whether you want a small batch or a large amount, you can rely on us to fulfill your orders quickly and reliably, allowing you to enjoy the freshest products without delay."
					/>
					<WhyUsList
						icon={<FaHandHoldingDollar style={iconStyle} />}
						header={'Cost Effective'}
						text="Quality should not be too costly, and at Iphecho, we are devoted to providing affordable solutions. We can offer affordable costs while maintaining quality thanks to effective processes and useful partnerships. Enjoy great items at affordable costs, allowing you to explore new options while increasing your savings."
					/>
				</ul>
			</div>
		</section>
	);
}
