export default function ReviewHeader({ text1, text2, text3 }) {
	return (
		<header>
			<p className="font-semibold lg:mx-64 mx-auto text-center text-4xl mb-8 text-deep-blue">
				{text1}
				<br className="sm:block hidden" /> {text2}
			</p>
			<p className="text-lg text-[#b9b9b9] mx-auto font-medium text-center mb-4 sm:w-3/4 lg:w-[35%]">{text3}</p>
		</header>
	);
}
