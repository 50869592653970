import React, { useState } from "react"
import { FaChevronDown } from "react-icons/fa6";
import { ArabFlag, NigeriaFlag, UkFlag } from "../images/logos";

const LanguageDropdown = () => {
    const [isDropDownOpen, setDropDownOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("English"); 

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        setDropDownOpen(false);
    }

    const getFlag = (language) => {
        return language === 'English'? UkFlag : language === 'Arabic'? ArabFlag : NigeriaFlag;
    }

    return (
        <div className="relative inline-block text-left ms-5 me-12" onMouseEnter={() => setDropDownOpen(true)} onMouseLeave={() => setDropDownOpen(false)}>
            <button type="button" className="flex justify-center items-center w-full sm:px-4 px-2 py-2 text-sm font-medium text-white bg-light-orange hover:bg-deep-orange border border-transparent rounded-md focus:outline-none shadow-sm" id="options-menu" aria-haspopup="true" aria-expanded={isDropDownOpen}>
                <span className="xl:block hidden">{selectedLanguage}</span>
                <span className="xl:hidden w-5 h-5 rounded-full overflow-hidden"><img src={getFlag(selectedLanguage)} alt={`${selectedLanguage}-flag`} className="w-full h-full" /></span>
                <span className="ml-1 font-bold"><FaChevronDown /></span>
            </button>
            {isDropDownOpen && (
                <div className="absolute right-0 mt-[0.1rem] space-y-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <ul className="sm:w-full w-1/2 float-left">
                        <li onClick={()=>handleLanguageSelect('English')} className="xl:px-7 sm:px-4 px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 hover:cursor-pointer">
                            <span>English</span>
                            <span className="display-hidden"><img src="" alt="" /></span>
                        </li>
                        <li onClick={()=>handleLanguageSelect('Igbo')} className="xl:px-7 sm:px-4 px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 hover:cursor-pointer">
                            <span>Igbo</span>
                            <span className="display-hidden"><img src="" alt="" /></span>
                        </li>
                        <li onClick={()=>handleLanguageSelect('Yoruba')} className="xl:px-7 sm:px-4 px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 hover:cursor-pointer">
                            <span>Yoruba</span>
                            <span className="display-hidden"><img src="" alt="" /></span>
                        </li>
                        <li onClick={()=>handleLanguageSelect('Hausa')} className="xl:px-7 sm:px-4 px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 hover:cursor-pointer">
                            <span>Hausa</span>
                            <span className="display-hidden"><img src="" alt="" /></span>
                        </li>
                        <li onClick={()=>handleLanguageSelect('Arabic')} className="xl:px-7 sm:px-4 px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 hover:cursor-pointer">
                            <span>Arabic</span>
                            <span className="display-hidden"><img src="" alt="" /></span>
                        </li>
                    </ul>
                </div>
            )}
            
        </div>
    )
}

export default LanguageDropdown;