import React from 'react'
import '../../app.css'
import AboutHero from '../../components/about/AboutHero';
import AboutQuote from '../../components/about/AboutQuote';
import AboutGallery from '../../components/about/AboutGallery';
import AboutPartners from '../../components/about/AboutPartners';
import NavBar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import VisionValues from '../../components/about/VisionValues';
import { Cashew, whyUs } from '../../components/images/images';
import Approach from '../../components/about/Approach';
import AboutPlant from '../../components/about/AboutPlant';

export default function About() {
    return (
        <div className="font-TT Commons">
            <div className="sm:mx-12 mx-6 w-auto mt-4 overflow-hidden">
                <NavBar/>
                <AboutHero/>
                <AboutQuote/>
                <VisionValues 
                    img={whyUs} 
                    heading='Our values' 
                    p1={<><span style={{ fontSize: '20px', color: '#ec911e', fontWeight: '700' }}>Quality:</span> We are committed to maintaining the highest standards of quality in everything we do, from sourcing our products to delivering them to our customers.</>}
                    p2={<><span style={{ fontSize: '20px', color: '#0145ca', fontWeight: '700' }}>Integrity:</span> We conduct our business with honesty and transparency, building trust with our partners and clients through ethical practices.</>}
                    p3={<><span style={{ fontSize: '20px', color: '#34A853', fontWeight: '700' }}>Customer Satisfaction:</span> Our customers are at the heart of our business. We strive to exceed their expectations by offering tailored solutions and unparalleled service.</>}
                    p4={<><span style={{ fontSize: '20px', color: '#FBBC05', fontWeight: '700' }}>Sustainability:</span> We believe in responsible business practices that protect the environment and support the well-being of the communities we work with. Sustainability is a core principle guiding our operations.</>}
                    p5={<><span style={{ fontSize: '20px', color: 'tomato', fontWeight: '700' }}>Innovation:</span> We continuously seek new ways to improve our processes, products, and services, staying ahead of industry trends to deliver value to our customers.</>} 
                    direction='row' 
                />

                <VisionValues 
                    img={Cashew} 
                    heading='Our vision' 
                    p1='At Iphecho Nig Limited,' 
                    p2=' Our vision is to become a global leader in the agro-commodities and logistics industry, renowned for our excellence in quality, reliability, and sustainable practices. We aim to connect markets across continents by providing exceptional products and services that meet the evolving needs of our customers, while contributing to the growth and development of the communities we operate in..' 
                    direction='reverse' />
                <Approach />
                <AboutPlant/>
                <AboutGallery/>
                <AboutPartners/>
            </div>
            <Footer />

        </div>
    );
};

