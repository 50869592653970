export const Description = ({product}) => {
  const paragraphs = product.split('\n\n');
  return (
    <div>
      <h4 className='text-deep-blue font-bold my-10'>Description</h4>
      {paragraphs.map((paragraph, index) => (
        <p key={index} className='text-gray-500 mb-3'>{paragraph}</p>
      ))}
    </div>
  )
}
