import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { ProductImageCard } from "./ProductImageCard";

function Arrow({
  onClick,
  isDisabled,
  direction,
}) {
  return (
    <div
      className={`absolute top-0 sm:mt-[10rem] mt-[4.5rem]  ${
        direction === "previous"
          ? "left-0 -ml-[3rem]"
          : "right-0 sm:-mr-[2.5rem] -mr-[2.8rem]"
      } z-[1] sm:mt-[30%] sm:-translate-y-1/2 
      ${isDisabled ? "cursor-default" : "cursor-pointer"}`}
    >
      <button
        className={`text-2xl p-5 ${
          isDisabled ? "text-gray-300" : "text-gray-600 hover:text-gray-900"
        }`}
        onClick={onClick}
        disabled={isDisabled}
      >
        {direction === "next" ? <FaChevronRight /> : <FaChevronLeft />}
      </button>
    </div>
  );
}

export const ProductImage = ({ productGroup, onImageChange }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNext = () => {
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };

  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide === productGroup.length - 1;

  const settings = {
    dots: false,
    infinite: false,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <Arrow
        onClick={handleNext}
        isDisabled={isLastSlide}
        direction="next"
      />
    ),
    prevArrow: (
      <Arrow
        onClick={handlePrev}
        isDisabled={isFirstSlide}
        direction="previous"
      />
    ),
    afterChange: (index) => {
      setCurrentSlide(index);
      onImageChange(index);
    },
  };

  return (
    <div className="w-full sm:w-[500px] h-[250px] mx-auto">
      <Slider {...settings}>
        {productGroup.map((product, index) => (
          <ProductImageCard
            key={index}
            backgroundImage={product.photo}
            title={product.title}
          />
        ))}
      </Slider>
    </div>
  );
};
