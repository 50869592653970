import React, {useEffect, useState} from 'react';
import {Line} from '../../assets/svg/icons';
import HomeHeader from '../home/HomeHeader';
import {Charcoal, Zobo, Sesame, Cashew, Tigernut, Pepper, Ginger, CashewKernel, SoyaBeans, Tumeric} from '../images/images';
import {Lcci, Nsc, Ilo, Nacpdean, SME, FMOE} from '../images/logos';

const content = [
	{
		photo: Charcoal,
		text: 'charcoal',
		paragraph: "Discover the legacy of hardwood charcoal – a timeless burn, swift ignition, and no sparks, smoke, or odors. With minimal ash and a radiant red-hot glow, it transforms grilling into a tradition of excellence, elevating your culinary journey with authentic flavor infusion."
	},
	{
		photo: Zobo,
		text: 'dried hibiscus leaves',
		paragraph: "Hibiscus petals, obtained from the flower's calyces, are widely used in global traditional cuisines. In Africa and the Middle East, they are dried and powdered for flexible culinary use."
	},
	{
		photo: Sesame,
		text: 'natural sesame seeds',
		paragraph: "Small, oval seeds from the sesame plant (Sesamum indicum), native to Africa, offer a nutty, slightly sweet flavor and crunchy texture. Widely used in global cuisine and baking."
	},
	{
		photo: Cashew,
		text: 'raw cashew seeds in shell',
		paragraph: 'The seeds of the cashew tree, which is native to South America but is now grown in many tropical countries around the world, surrounded by a double shell that contains a caustic oil.'
	},
	{
		photo: Tigernut,
		text: 'tiger nuts',
		paragraph: "Tiger nuts, commonly known as Chufa, are small cylindrical tubers produced by the Cyperus esculentus plant, native to Africa. Long used as a food source for humans and livestock, these nutrient-rich tubers add a delightful crunch to various culinary delights."
	},
	{
		photo: Pepper,
		text: 'dried red chili pepper',
		paragraph: "Dried red chili peppers, a global culinary staple, are made by sun-drying or dehydrating mature peppers, intensifying their flavor by removing moisture."
	},	
	{
		photo: Ginger,
		text: 'dried split ginger',
		paragraph: "Sliced thin and dried, this ginger type enhances culinary creations and plays a role in traditional herbal medicine, bringing versatility to kitchen and wellness practices."
	},
	{
		photo: CashewKernel,
		text: 'cashew kernel',
    paragraph: "Cashew kernels, the edible seeds of the cashew apple, are prized for their creamy texture and rich, buttery flavor. They are a versatile ingredient in both sweet and savory dishes, adding a delightful crunch and nutritional boost to recipes around the world."
	},
	{
		photo: SoyaBeans,
		text: 'soyabeans',
		paragraph: "Soybeans, also known as soya beans, are a type of legume that is widely consumed as a source of plant-based protein around the world. They are native to East Asia and have been cultivated for over 5,000 years."
	},
	{
		photo: Tumeric,
		text: 'tumeric',
    paragraph: "Turmeric, derived from the root of the Curcuma longa plant, is a vibrant yellow spice known for its earthy flavor and powerful anti-inflammatory properties. Widely used in cooking and traditional medicine, it brings warmth and color to a variety of dishes while offering numerous health benefits."
	}
];

export default function Partners() {
	const [index, setIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setIndex((prevIndex) => (prevIndex + 1) % content.length);
		}, 5000); // changes every 5 seconds
	
		return () => clearInterval(interval); // cleanup on unmount
	}, []);  


	return (
		<section className="sm:mt-[6.5rem] mt-14">
			<HomeHeader
				photo={content[index].photo}
				text1={content[index].text}
				paragraph1={content[index].paragraph}
			/>
			<div className="drop-shadow-2xl bg-light-white relative bottom-[50px] rounded-xl lg:mx-12 mx-6 h-16 sm:p-12 py-10 px-2 flex justify-between items-center">
				
				<div className='w-20'> <img src={Lcci} alt="LCCI logo" className='w-full h-full' /> </div>
				<Line />
				<div className='w-20'> <img src={SME} alt="LCCI logo" className='w-full h-full' /> </div>
				<Line />
				<div className='w-20'> <img src={Nsc} alt="LCCI logo" className='w-full h-full' /> </div>
				<Line />
				<div className='w-20'> <img src={Ilo} alt="LCCI logo" className='w-full h-full' /> </div>
				<Line />
				<div className='w-20'> <img src={FMOE} alt="LCCI logo" className='w-full h-full' /> </div>
				<Line />
				<div className='w-20'> <img src={Nacpdean} alt="LCCI logo" className='w-full h-full' /> </div>
			</div>
		</section>
	);
}
