import { Link } from "react-router-dom";

const FooterProduct = ({ title, to, imageUrl }) => {
  return (
    <li className="mb-2 my-2 hover:text-light-orange hover:cursor-pointer">
        <div className="flex items-center">
            <div className="w-10 h-10 rounded-full overflow-hidden border-2 border-white">
                <img src={imageUrl} alt={title} className="object-cover w-full h-full" />
            </div>
            <Link to={to} className="ml-3">{title}</Link>
        </div>
    </li>
  );
};

export default FooterProduct;
