import React from 'react';
import { CeoProfile, StoneFlower2 } from '../images/images'


export default function AboutQuote() {
    return (
        <div className="about-quote-container mb-0">
            <div className="relative text-white min-h-max py-28">
                <div className='absolute inset-0'>
                    <img 
                        src={StoneFlower2} 
                        alt="about quote"
                        className="w-full h-full object-cover object-center"
                    />
                </div>
                <div className="temp14-txt relative h-auto pl-[8%] md:pr-[50%] lg:pr-[60%]">
                    <h4 className="text-4xl font-normal italic mb-8 text-gray-50 leading-tight tracking-tight">
                        “We are a global company dedicated to <strong className="font-normal">exporting: agro-commodities.</strong>”
                    </h4>
                    <div className="flex items-center">
                        <div className="w-20 h-20 rounded-full overflow-hidden">
                            <img src={CeoProfile} alt="Obietoh Ifeanyi" className="w-full h-full object-cover object-center" />
                        </div>
                        <div className="ml-4">
                            <p className="font-medium text-lg text-gray-100 leading-normal tracking-tight mb-1">Obietoh Ifeanyi,</p>
                            <p className="font-normal text-gray-50">CEO</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

