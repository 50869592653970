export default function AboutPlant() {


    return (
        <section className='sm:mt-[6.5rem] mt-14 mb-0'>
            <div className='w-full'>
                <div className='xl:pb-[150px] md:pb-24 pb-12'>
                    <div className='container mx-auto max-w-screen-xl xl:flex items-center relative'>
                        {/* <div className='absolute w-full md:mt-0 mt-28 opacity-10'>
                            <h1 className='text-gray-900 font-bold text-center lg:text-[15rem] md:text-[12rem] sm:text-[9rem] text-[7rem]'>IPHECHO</h1>
                            <h1 className='text-gray-900 font-bold text-center lg:text-[15rem] md:text-[12rem] sm:text-[9rem] text-[7rem] tracking-[0.06em] lg:-mt-[8.5rem] -mt-[3rem] pt-0'>LIMITED</h1>
                        </div> */}


                        <div className='xl:w-7/12 mx-auto xl:ml-5'>
                          <div className="vimeo-wrap relative w-full mb-0" style={{ padding: '56.25% 0 0 0' }}>
                              <iframe 
                                  src="https://player.vimeo.com/video/994833088?badge=0&amp;autopause=0&amp;player_id=0&amp;autoplay=1&amp;loop=1&amp;title=0&amp;byline=0&amp;portrait=0&amp;muted=1&amp;app_id=58479" 
                                  frameborder="0" 
                                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                                  className='absolute top-0 left-0 h-full w-full float-left mb-6 rounded-3xl object-cover'
                                  title="Iphecho-processing-plant"
                                  id="video"
                                  data-ready="true">
                              </iframe>
                            	<script src="https://player.vimeo.com/api/player.js"></script>
                          </div>
                        </div>

                        <div className='xl:w-2/4 lg:w-3/4 mx-auto w-full pl-8 pr-6'>
                            <h1 className='text-6xl font-black text-deep-orange leading-none tracking-tight mb-8 text-left'>
                              Processing Plant
                            </h1>

                            <p className='mb-8 text-xl'>
                              Our processing plant is equipped with advanced machinery, including a color sorting machine with a daily capacity of 20 MT and a cleaning machine capable of handling 140 MT per day. For Dry Split Ginger, we utilize a washer, splitter, and dryer with a capacity of 200 MT per day, ensuring a product of superior quality with uniform thickness, free from foreign matter, mold, and aflatoxin. Thanks to our state-of-the-art equipment, we consistently deliver products of exceptional quality and consistency.
                            </p> 
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
};
