export default function HomeHeader({ photo, text1, paragraph1 }) {
	return (
        <div className="relative text-center text-white xl:h-[650px] md:h-[550px] h-[450px]">
            <div className="h-full overflow-hidden flex justify-center items-center">
                <img src={photo} alt="" className="w-full h-full rounded-3xl" />
            </div>
            
            {/*Hero Overlay */}
            <div className="absolute inset-0 bg-black opacity-30 rounded-3xl h-auto"></div>

            <div className="relative xl:bottom-[500px] md:bottom-[450px] bottom-[350px] z-6">
                <p className="font-bold sm:text-6xl text-3xl mx-auto xl:w-[40%] lg:w-1/2 sm:w-3/4 w-[90%] uppercase">{text1}</p>
                <p className="font-medium mx-auto sm:pt-12 pt-6 sm:text-xl text-base xl:w-[35%] lg:w-1/2 sm:w-3/4 w-[90%]">{paragraph1}</p>
            </div>
        </div>
    );
}
