import { CheckedStar, Comment, UncheckedStar } from '../../assets/svg/icons';
import { Audu, Harriet, Klaus } from '../images/images';
import ReviewCard from '../reviews/ReviewCard';
import ReviewHeader from '../reviews/ReviewHeader';
import Slider from 'react-slick';

export default function Review() {
	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		speed: 5000,
		autoplaySpeed: 500,
		cssEase: "linear",
		responsive:  [
			{
				breakpoint: 1280,
				settings: { 
					slidesToShow: 3,
					slidesToScroll: 1,
					
				}
			},
			{
				breakpoint: 768,
				settings: { 
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}
	return (
		<section className="relative lg:top-[10rem] top-28 lg:mb-[20rem] mb-40">
			<ReviewHeader
				text1="What our customers are"
				text2="saying about us."
				text3="Reviews from our previous customers."
			/>
			<div className="flex justify-center items-center">
				<Comment />
			</div>
			<Slider {...settings} className='-mt-24'>
				<ReviewCard
					text="Using Cactus is the best decision I’ve ever made. 
                    I’ve lost my job a lot before I found a stable one, but before that
                    Cactus was really there for me in those difficult times. 
                    I will always recommend Cactus to my colleagues."
					photo={Klaus}
					client="Klaus Fischer"
					job="Lawyer Lami and Sons"
					rating1={<CheckedStar />}
					rating2={<CheckedStar />}
					rating3={<CheckedStar />}
					rating4={<UncheckedStar />}
					rating5={<UncheckedStar />}
				/>
				<ReviewCard
					text="I could remember last year a friend recommended cactus to me,
					then I suddenly lost my job. I was so broke. Thank God for Cactus, 
					I was able to stay on my feet and pay my bills while looking for a new job."
					photo={Harriet}
					client="Harriet Nwagwu"
					job="Banker, UBA"
					rating1={<CheckedStar />}
					rating2={<CheckedStar />}
					rating3={<CheckedStar />}
					rating4={<CheckedStar />}
					rating5={<CheckedStar />}
				/>
				<ReviewCard
					text="Subscribing to Cactus Job Insurance company to
					secure my stability during job hunting
					is the best decision I made. The transactions are done seamlessly.
					Cactus is indeed true to her goals and objectives."
					photo={Audu}
					client="Audu Umaru"
					job="Banker, UBA"
					rating1={<CheckedStar />}
					rating2={<CheckedStar />}
					rating3={<CheckedStar />}
					rating4={<CheckedStar />}
					rating5={<UncheckedStar />}
				/>
				<ReviewCard
					text="Using Cactus is the best decision I’ve ever made. 
                    I’ve lost my job a lot before I found a stable one, but before that
                    Cactus was really there for me in those difficult times. 
                    I will always recommend Cactus to my collegues."
					photo={Klaus}
					client="Klaus Fischer"
					job="Lawyer Lami and Sons"
					rating1={<CheckedStar />}
					rating2={<CheckedStar />}
					rating3={<CheckedStar />}
					rating4={<UncheckedStar />}
					rating5={<UncheckedStar />}
				/>
				<ReviewCard
					text="I could remember last year a friend recommended cactus to me,
					then I suddenly lost my job. I was so broke. Thank God for Cactus, 
					I was able to stay on my feet and pay my bills while looking for a new job."
					photo={Harriet}
					client="Harriet Nwagwu"
					job="Banker, UBA"
					rating1={<CheckedStar />}
					rating2={<CheckedStar />}
					rating3={<CheckedStar />}
					rating4={<CheckedStar />}
					rating5={<CheckedStar />}
				/>
				<ReviewCard
					text="Subscribing to Cactus Job Insurance company to
					secure my stability during job hunting
					is the best decision I made. The transactions are done seamlessly.
					Cactus is indeed true to her goals and objectives."
					photo={Audu}
					client="Audu Umaru"
					job="Banker, UBA"
					rating1={<CheckedStar />}
					rating2={<CheckedStar />}
					rating3={<CheckedStar />}
					rating4={<CheckedStar />}
					rating5={<UncheckedStar />}
				/>
			</Slider>
		</section>
	);
}
