import React, { useState } from 'react';
import FaqsItem from '../faqs/FaqsItems';
import faqData from '../../utils/data';
import ReviewHeader from '../reviews/ReviewHeader';

export default function FaqsBody ()  {

	const [openIndex, setOpenIndex] = useState(0);

	const toggleAccordion = (index) => {
		setOpenIndex(index === openIndex ? -1 : index);
	};

	return (
		<div>
			<ReviewHeader text1="FAQs" />

			<h2 className="text-xl text-gray-400 text-center  font-semibold mb-5">
				Some of the frequently asked questions our clients from all over the world ask us often.
			</h2>

			{faqData.map((faq, index) => (
				<FaqsItem
					key={index}
					question={faq.question}
					answer={faq.answer}
					isOpen={index === openIndex}
					onToggleAccordion={() => toggleAccordion(index)}
				/>
			))}
		</div>
	);
};
