export default function WhyUsList({ header, text, icon }) {
	return (
		<div className="xl:mb-12 mb-6">
			<div className="rounded-full bg-gray-100 w-[45px] p-[10px] mb-4">{icon}</div>
			<div>
				<li className="font-semibold text-2xl text-deep-blue">{header}</li>
				<p className="text-sm font-normal xl:w-[80%] w-full">{text}</p>
			</div>
		</div>
	);
}
