// import React, { useState } from 'react';
// import FaQsNav from '../../components/faqs/FaqsNav';
// import Footer from '../../components/footer/Footer';
// import FaQsBody from '../../components/faqs/FaqsBody';
// import FAQs from '../../assets/images/faq2.jpeg';
// // import Productmenu from '../../components/product/ProductMenu';

// export default function Faqs ()  {
// 	const [ state, setState ] = useState(false);
// 	const onClick = () => setState(!state);
// 	return (
// 		<>
// 			<div className="relative font-TT Commons">
				

// 				<div className=" text-center flex flex-col ">

// 					<div className="right-0 top-24 absolute inset-0 bg-black opacity-40 h-screen"></div>

// 					<div className='h-screen w-full mt-24'>
// 						<img src={FAQs} alt="" className=' h-full w-full bg-contain bg-center'  />
// 					</div>



// 					<FaQsNav text1="Frequently asked Questions" onClick={onClick} />
// 					{/* {state && <Productmenu />} */}

// 				</div>

// 				<div className="md:mx-32 mx-9 md:mt-[23.5rem] mt-24">
// 					<FaQsBody />
// 				</div>

// 				<div className="mx-12">
// 					<hr className="mt-[7rem]" />
// 				</div>
// 			</div>
// 			<Footer />
// 		</>
// 	);
// };



import React, { useState } from 'react';
import FaQsNav from '../../components/faqs/FaqsNav';
import Footer from '../../components/footer/Footer';
import FaQsBody from '../../components/faqs/FaqsBody';
import FAQs from '../../assets/images/faq2.jpeg';
// import Productmenu from '../../components/product/ProductMenu';

export default function Faqs ()  {
	const [ state, setState ] = useState(false);
	const onClick = () => setState(!state);
	return (
		<>
			<div className="relative font-TT Commons">
				

				<div className=" text-center flex flex-col ">

					<div className="right-0 top-24 absolute inset-0 bg-black opacity-40 h-screen"></div>

					<div className='h-screen w-full mt-24'>
						<img src={FAQs} alt="" className=' h-full w-full bg-contain bg-center'  />
					</div>



					<FaQsNav text1="Frequently asked Questions" onClick={onClick} />
					{/* {state && <Productmenu />} */}

				</div>

				<div className="md:mx-32 mx-9 mt-[23.5rem] ">
					<FaQsBody />
				</div>

				<div className="mx-12">
					<hr className="mt-[7rem]" />
				</div>
			</div>
			<Footer />
		</>
	);
};
