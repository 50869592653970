import React, { useState } from 'react'
import { ContactCard } from './ContactCard';
import locations from '../../utils/locations.json'

const Locator = () => {
    const [selectedLocation, setSelectedLocation] = useState('offices');

    const handleLocationChange = (e) => {
        setSelectedLocation(e.target.value.toLowerCase());
    };

    const warehouses = locations.find(location => location.locationType === 'warehouses')?.locationDetails

    const processingPlants =  locations.find(location => location.locationType === 'processing plants')?.locationDetails

    const offices = locations.find(location => location.locationType === 'offices')?.locationDetails

    const allAddress = [
        ...offices,
        ...warehouses,
        ...processingPlants
    ]

    const locationOptions = [
        {value: 'offices', label: 'Offices'},
        {value: 'warehouses', label: 'Warehouses'},
        {value: 'processing-plants', label: 'Processing Plants'},
        {value: 'all', label: 'All Locations'}
    ]

    const locationData = {
        warehouses,
        offices,
        'processing-plants': processingPlants,
        all: allAddress
    }

    const getAddressByType = () => locationData[selectedLocation]

    const addresses = getAddressByType()

  return (
    <div className='my-16'>
        <h3 className='text-center text-5xl font-bold text-deep-blue'>Locate us</h3>
        <p className='text-center text-xl text-gray-500 my-3'>Want to meet us physically? Locate our venues.</p>
        <div className='my-10'>
            <select name="location" id="location" onChange={handleLocationChange} className='block md:w-1/2 w-3/4 mx-auto rounded-3xl border-0 py-3 px-5 text-black ring-2 ring-gray-300 ring-inset sm:text-sm sm:leading-6'>
                {locationOptions.map(option => (
                    <option value={option.value} key={option.value}>{option.label}</option>
                ))}
            </select> 
            <div className='flex justify-center items-center mt-16'>
                {selectedLocation && (
                    <div className='grid md:grid-cols-2 gap-x-20 gap-y-10'>
                        {addresses.map((address, index) => (
                            <ContactCard key={index} type={address.type} name={address.name} address={address.address} />
                        ))}
                    </div>
                )}
            </div>
            
        </div>
    </div>
  )
}

export default Locator