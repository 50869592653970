import React from 'react'
import { FMOE, Ilo, Lcci, Nacpdean, Nepc, Nsc, SME } from '../images/logos'
import Slider from 'react-slick'

const PartnersCard = ({img}) => {
    return (
        <div className="border-2 drop-shadow bg-light-white rounded-xl mt-10 lg:h-48 h-28 mx-10 flex justify-center items-center">
            <img src={img} alt="" className='lg:w-1/2' />
        </div>
    )
}

export default function AboutPartners() {
    const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		speed: 5000,
		autoplaySpeed: 500,
		cssEase: "linear",
		responsive:  [
			{
				breakpoint: 1280,
				settings: { 
					slidesToShow: 3,
					slidesToScroll: 1,
					
				}
			},
			{
				breakpoint: 768,
				settings: { 
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}
    return (
        <section className="mb-0">
            <div className="pb-20 w-full">
                <div className="container mx-auto w-[96%] max-w-screen-xl">
                    <div className="w-full">
                        <div className="py-0 px-4 lg:px-[24%] aos-init" data-aos="fade-down" data-aos-duration="1000">
                            <h2 className='mb-10 text-3xl sm:text-5xl md:text-6xl lg:text-[52px] leading-none text-center tracking-tight font-black'>Professional Membership</h2>
                            <p className='mb-6 text-base sm:text-lg md:text-xl leading-relaxed text-center text-[#9f9f9f]'>As industry leaders, we set global standards in agro-commodity trade. Our professional memberships reflect our commitment to excellence, connecting us with key industry partners and ensuring we stay at the forefront of best practices.</p>
                            <p className='mb-6 text-base sm:text-lg md:text-xl leading-relaxed text-center text-[#9f9f9f]'> We're proud to represent and uphold the highest quality standards in everything we do.</p>
                        </div>


                        <Slider {...settings} className="relative">
				
                            <PartnersCard img={Lcci} />
                            <PartnersCard img={SME} />
                            <PartnersCard img={Nsc} />
                            <PartnersCard img={Ilo} />
                            <PartnersCard img={Nacpdean} />
                            <PartnersCard img={Nepc} />
                            <PartnersCard img={FMOE} />

                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}
