import React from 'react'
import GalleryCard from './GalleryCard'
import { Cashew2, Cassia, Charcoal3, Ginger2,  SoyaBeans1, StoneFlower, StoneFlower2, Tigernut, Zobo2, Zobo3 } from '../images/images';
import { Gif1, Gif2, Gif3, Gif4, Gif5, Gif6 } from '../images/gif';

export default function AboutGallery() {
  return (
    <section className="about-6 mb-0">
      <div className="w-full float-left py-[90px] ">
        <div className="mb-14">
          <div className="container mx-auto max-w-screen-xl">
            <div className="my-0 mx-auto px-4 sm:px-0 md:w-8/12 lg:w-6/12">
              <h2 data-aos="fade-down" data-aos-duration="1000" className="text-3xl sm:text-5xl md:text-6xl lg:text-6xl font-medium text-center tracking-tight leading-snug aos-init">
                From Harvest to Export
                <br /><strong className='font-black'>Quality You Can Trust</strong>
              </h2>
            </div>
          </div>
        </div>

        <div className="lg:mb-[150px] mb-[50px]">
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:gap-4 gap-x-5'>
            <div className=''>
              <GalleryCard img={Gif1} />
              <GalleryCard img={Cashew2} />
              <GalleryCard img={Ginger2} />
              <GalleryCard img={Gif4} />
            </div>
            <div className='lg:mt-12 sm:mt-0'>
              <GalleryCard img={Zobo2} />
              <GalleryCard img={SoyaBeans1} />
              <GalleryCard img={Charcoal3} />
              <GalleryCard img={Zobo3} />
            </div>
            <div className=''>
              <GalleryCard img={Gif6} />
              <GalleryCard img={Tigernut} />
              <GalleryCard img={StoneFlower} />
              <GalleryCard img={Gif2} />
            </div>
            <div className='lg:mt-12 sm:mt-0'>
              <GalleryCard img={StoneFlower2} />
              <GalleryCard img={Gif3} />
              <GalleryCard img={Gif5} />
              <GalleryCard img={Cassia} />
            </div>
          </div>
        </div>

        <div className="w-full float-left">
          <div className="container mx-auto max-w-screen-xl">
            <div className="py-0 px-4 sm:px-8 w-full float-left aos-init" data-aos="fade-down" data-aos-duration="1000">
              <div className="flex flex-wrap justify-between relative slider">
                <div className="mb-8 sm:mb-0" aria-hidden="true" tabIndex="0" role="tabpanel" id="slick-slide00" aria-describedby="slick-slide-control00">
                  <h6 className="text-4xl sm:text-[82px] font-medium tracking-[-0.03em] leading-[1.1] mb-[15px] text-light-orange text-left">6+</h6>
                  <p className='text-[#707070]'>
                    <strong className='font-semibold'>Years</strong>
                  </p>
                  <p className='text-[#707070] text-sm tracking-tighter'>of agro-commodities export experience</p>
                </div>

                <div className="mb-8 sm:mb-0" aria-hidden="true" tabIndex="-1" role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01">
                  <h6 className="text-4xl sm:text-[82px] font-medium tracking-[-0.03em] leading-[1.1] mb-[15px] text-light-orange text-left">6+</h6>
                  <p className='text-[#707070]'>
                    <strong className='font-semibold'>Factories</strong>
                  </p>
                  <p className='text-[#707070] text-sm tracking-tighter'>factories worldwide</p>
                </div>

                <div className="mb-8 sm:mb-0" aria-hidden="true" tabIndex="-1" role="tabpanel" id="slick-slide02" aria-describedby="slick-slide-control02">
                  <h6 className="text-4xl sm:text-[82px] font-medium tracking-[-0.03em] leading-[1.1] mb-[15px] text-light-orange text-left">100+</h6>
                  <p className='text-[#707070]'>
                    <strong className='font-semibold'>Agro-Commodities</strong>
                  </p>
                  <p className='text-[#707070] text-sm tracking-tighter'>origins</p>
                </div>
{/* 
                <div className="mb-8 sm:mb-0" aria-hidden="true" tabIndex="-1" role="tabpanel" id="slick-slide03" aria-describedby="slick-slide-control03">
                  <h6 className="text-4xl sm:text-[82px] font-medium tracking-[-0.03em] leading-[1.1] mb-[15px] text-light-orange text-left">5500</h6>
                  <p className='text-[#707070]'>
                    <strong className='font-semibold'>Peanut</strong>
                  </p>
                  <p className='text-[#707070] text-sm tracking-tighter'>trade sources</p>
                </div> */}

                <div className="mb-8 sm:mb-0" aria-hidden="true" tabIndex="-1" role="tabpanel" id="slick-slide04" aria-describedby="slick-slide-control04">
                  <h6 className="text-4xl sm:text-[82px] font-medium tracking-[-0.03em] leading-[1.1] mb-[15px] text-light-orange text-left">15</h6>
                  <p className='text-[#707070]'>
                    <strong className='font-semibold'>Distribution</strong>
                  </p>
                  <p className='text-[#707070] text-sm tracking-tighter'>subsidiaries</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}
