// import { useState } from "react"
// import { ReviewForm } from "../reviews/ReviewForm"
import { Description } from "./Description"

export const AboutProduct = ({products, productIndex, productGroup, groupName}) => {
    //  Comment out state for displaying the review form or description. Default is to display description.
    // const [view, setView] = useState('desc');

    // const handleViewChange = (view) => {
    //     console.log(`The ${view} is on display`)
    //     setView(view);
    // }

    const selectedProduct  = productGroup[productIndex];

    const filteredProductGroup = products.find(group => group.groupName.toLowerCase() === groupName.toLowerCase())?.products;

    const filteredProduct = filteredProductGroup.find(product => product.productName.toLowerCase() === selectedProduct.title.toLowerCase());

  return (
    <div className="sm:px-16 ">
        {/* Div for buttons for when we implement review form */}
        {/* <div className="flex justify-evenly w-60">
            <button className={`bg-deep-blue text-white font-bold p-3 rounded-xl ${view === 'desc' ? 'opacity-100' : 'opacity-50'}`} onClick={() => handleViewChange('desc')}>DESCRIPTION</button>
            <button className={`bg-deep-blue text-white font-bold p-3 rounded-xl ${view === 'review' ? 'opacity-100' : 'opacity-50'}`} onClick={() => handleViewChange('review')}>REVIEW</button>
        </div> */}
        <div className="w-full border-gray-200 border-t-2">
            {/* Comment out view change implementation */}
            {/* <div className="" id="desc">
                {view === 'desc' && <Description key={productIndex} product={filteredProduct.description} />}
            </div> 
            <div className="" id="review">
                {view === 'review' && <ReviewForm />}
            </div> */}
            <Description key={productIndex} product={filteredProduct.description} />
        </div>
    </div>
  )
}
