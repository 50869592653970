export const ProductImageCard = ({ backgroundImage, title }) => {
  return (
    <div className="flex justify-center items-center">
      <div>
        <h4 className="text-center text-lg text-gray-600 font-bold uppercase">{title}</h4>
        <div className="sm:w-[500px] w-full sm:p-4 sm:mx-3 sm:h-[250px] h-[150px] flex justify-center items-center overflow-hidden">
          <img src={backgroundImage} alt={title} />
        </div>
      </div>
    </div>
  );
};
