export const ProductHero = ({ photo, text1}) => {
	return (
        <div className="relative text-center text-white lg:h-[550px] h-[350px]">
            <div className="flex items-center justify-center h-full overflow-hidden">
                <img src={photo} alt="" className="w-full h-full rounded-3xl" />
            </div>
            
            {/*Hero Overlay */}
            <div className="absolute inset-0 bg-black opacity-30 rounded-3xl h-full"></div>

            <div className="relative lg:bottom-[320px] bottom-[210px] z-6">
                <p className="font-bold text-6xl uppercase">{text1}</p>
            </div>
        </div>
    );
}