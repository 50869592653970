import React from 'react'

const GalleryCard = ({ img }) => {
  return (
    <div className="w-full h-60 mt-6 rounded-2xl bg-cover bg-no-repeat" style={{ backgroundImage: `url(${img})` }}>
      {/* <img src={img} alt="Gallery" className=' bg-slate-500 rounded-2xl aos-init mx-0 w-full' data-aos="zoom-out" /> */}
    </div>
  );
};

export default GalleryCard