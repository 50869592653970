import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Faqs from './pages/Faqs';
import About from './pages/About';
import { ContactUs } from './pages/ContactUs';
import {CharcoalPage, Herbs, Nuts, Seeds, Spices} from './pages/Products/index'
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <>
      <ScrollToTop/>
      <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/faqs" element={<Faqs/>} />
          <Route path="/about" element={<About/>} />
          <Route path='/products/*' element={<ProductRoutes/>} />
          <Route path='/contact-us' element={<ContactUs />} />
        
      </Routes>
    </>

  );
}

function ProductRoutes () {
  return (
    <Routes>
      <Route path='/spices' element={<Spices />} />
      <Route path='/nuts' element={<Nuts/>} />
      <Route path='/herbs' element={<Herbs />} />
      <Route path='/charcoal' element={<CharcoalPage />} />
      <Route path='/seeds' element={<Seeds />} />
    </Routes>
  )
}

export default App;
