import React from 'react'

const VisionValues = ({ img, heading, p1, p2, p3, p4, p5, direction }) => {
  const imageClasses = "lg:w-1/2 w-full lg:h-auto h-1/2 rounded-2xl overflow-hidden";
  const contentClasses = `lg:w-1/2 w-full lg:mt-0 mt-5 ${direction === "reverse" ? "lg:mr-20" : "lg:ml-20"} ml-0`;

  return (
    <div className="flex justify-center items-center lg:w-3/4 w-full h-auto mx-auto md:my-10 my-5 lg:pt-28 pt-10">
      <div className={`lg:flex ${direction === "reverse" ? "flex-row-reverse" : "flex-row"} justify-between`}>
        <div className={imageClasses}>
          <img src={img} alt="" className="w-full h-full object-cover" />
        </div>
        <div className={contentClasses}>
          <h4 className="text-5xl font-bold text-gray-900 mb-8">{heading}</h4>
          <p className="text-gray-400 mb-5 text-xl">{p1}</p>
          <p className="text-gray-400 mb-5 text-xl">{p2}</p>
          <p className="text-gray-400 mb-5 text-xl">{p3}</p>
          <p className="text-gray-400 mb-5 text-xl">{p4}</p>
          <p className="text-gray-400 mb-5 text-xl">{p5}</p>
        </div>
      </div>
    </div>
  );
};


export default VisionValues