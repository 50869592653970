const faqData = [
    {
        question: 'WHAT KIND OF AGRICULTURAL COMMODITIES DOES YOUR COMPANY DEAL IN?',
        answer:
        'We specialize in supplying top-quality agricultural commodities, including Dry Hibiscus Flowers, Natural Sesame Seeds, Dry Split Ginger, Dry Red Chilli Peppers, Raw Cashew Nuts In Shell, Tiger Nuts, Cassia Tora Seeds, and Natural Hardwood Charcoal. Our commitment is to deliver excellence in quality and service. For inquiries, feel free to contact us.',
    },
    {
        question: 'WHERE DO YOU SOURCE YOUR AGRO COMMODITIES FROM?',
        answer:
        'We source our agro commodities directly from Nigerias organic farmlands, ensuring high quality for export.',
    },
    {
        question: 'HOW DO YOU ENSURE THE QUALITY OF YOUR AGRO COMMODITIES?',
        answer:
        'We ensure the quality of our agro commodities through rigorous/strict quality control measures. Regular inspections and tests are conducted to meet the highest standards and specifications.',
    },
    {
        question: 'WHAT IS YOUR MINIMUM ORDER QUANTITY?',
        answer:
        'Our minimum order quantity varies based on the commodity and destination country. For specific details, please reach out to us directly. Contact us for more information.',
    },
    {
        question: 'What are your shipping options?',
        answer:
        'We offer flexible shipping options to accommodate your needs, including standard and expedited shipping. Depending on your location and order size, we can arrange for sea freight, air freight, or land transportation. We work with reliable logistics partners to ensure your order is delivered safely and on time. Please contact us to discuss the best shipping option for your specific requirements.',
    },
    {
        question: 'Do you offer samples of your agro commodities?',
        answer:
        "Yes, we do offer samples of our agro commodities. We understand the importance of evaluating the quality of our products before making a larger purchase. Please get in touch with us to request a sample, and we'll be happy to assist you with the process, including any costs associated with sample delivery.",
    },
    {
        question: 'What countries do you export to?',
        answer:
        'We export our agro commodities to a wide range of countries across various continents, including North America, Europe, Asia, and Africa. Our export network is continuously expanding, and we are equipped to handle international shipping to meet your needs. If you have a specific destination in mind, please contact us for more detailed information about our export capabilities.',
    },
    {
        question: 'What are your payment terms?',
        answer:
        'We offer various payment terms depending on the size and nature of the order. Typically, we require an upfront payment or a letter of credit, but we are flexible and can work with our customers to find a payment plan that suits their needs. Our goal is to ensure a smooth and secure transaction for both parties. Please contact us to discuss specific payment arrangements.',
    }
];

export default faqData;