import React, { useState } from 'react';

export default function AboutHero() {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <section className='sm:mt-[6.5rem] mt-14 mb-0'>
            <div className='w-full'>
                <div className='xl:pb-[150px] md:pb-24 pb-12'>
                    <div className='container mx-auto max-w-screen-xl xl:flex items-center relative'>
                        <div className='absolute w-full md:mt-0 mt-28 opacity-10'>
                            <h1 className='text-gray-900 font-bold text-center lg:text-[15rem] md:text-[12rem] sm:text-[9rem] text-[7rem]'>IPHECHO</h1>
                            <h1 className='text-gray-900 font-bold text-center lg:text-[15rem] md:text-[12rem] sm:text-[9rem] text-[7rem] tracking-[0.06em] lg:-mt-[8.5rem] -mt-[3rem] pt-0'>LIMITED</h1>
                        </div>

                        <div className='xl:w-2/5 lg:w-3/4 mx-auto w-full pl-8 pr-6'>
                            <h1 className='text-6xl font-medium text-deep-blue leading-none tracking-tight mb-8 text-left'>
                                We’re <br className='xl:block hidden'/> <strong className='font-black text-deep-orange'>Iphecho Nig<br className='lg:block hidden'/> Limited</strong>
                            </h1>

                            <p className='mb-8 text-xl'>
                                Iphecho Nig Limited is a global trading organization specializing in the import and export of high-quality agro-commodities, logistics, and general merchandise. With extensive experience and expertise, we have become a trusted partner for clients across Europe, Asia, North America, and Central America, known for our ability to solve the challenges of sourcing and procuring African and Nigerian-origin agricultural products. Our reputation is built on guaranteed safety, exceptional quality, and reliable services.
                                {isExpanded && (
                                    <>
                                        <br /><br />
                                        We offer a diverse range of products, including wood charcoal, raw cashew nuts, hibiscus flowers, sesame seeds, dried split ginger, and more. To ensure smooth, fast, and reliable supply, we maintain our own fleet of trucks, easing logistics and enhancing our haulage capabilities. Our commitment to excellence in logistics makes us a dependable partner for all your sourcing needs.
                                        <br /><br />
                                        Iphecho Nig Limited was established to help international buyers safely and reliably procure agricultural products from Nigeria and other parts of Africa. We strive to be the leading and most reputable global trade business in Africa, prioritizing integrity, respect for our customers' views, and the building of strong, lasting relationships.
                                        <br /><br />
                                        We believe in forming strategic, long-term partnerships with clients who value our expertise and the quality we offer. By ensuring that every transaction is secure and beneficial for both our clients and our organization, we lay the foundation for sustained, mutually rewarding relationships.
                                    </>
                                )}
                            </p> 

                            <button onClick={toggleReadMore} className='text-white bg-deep-blue mt-4 px-8 py-2 rounded-lg hover:bg-light-blue'>
                                {isExpanded ? 'Read Less' : 'Read More'}
                            </button>
                        </div>

                        <div className='xl:w-7/12 mx-auto xl:ml-5'>
                        <div className="vimeo-wrap relative w-full mb-0" style={{ padding: '56.25% 0 0 0' }}>
                                <iframe 
                                    src="https://player.vimeo.com/video/994785885?badge=0&amp;autopause=0&amp;player_id=0&amp;autoplay=1&amp;loop=1&amp;title=0&amp;byline=0&amp;portrait=0&amp;muted=1&amp;app_id=58479" frameborder="0" 
                                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                                    className='absolute top-0 left-0 h-full w-full float-left mb-6 rounded-3xl object-cover'
                                    title="Iphecho-nig-limited-vid"
                                    id="video"
                                    data-ready="true">
                                </iframe>
                            	<script src="https://player.vimeo.com/api/player.js"></script>
                            </div>
                            {/* <div className="vimeo-wrap relative w-full mb-0" style={{ padding: '56.25% 0 0 0' }}>
                                <iframe
                                    src="https://player.vimeo.com/video/723289412?h=a1b428ed4b&amp;autoplay=1&amp;loop=1&amp;title=0&amp;byline=0&amp;portrait=0&amp;muted=1&amp;api=1"
                                    className='absolute top-0 left-0 h-full w-full float-left mb-6 rounded-3xl object-cover'
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                    title="Agrofoods Global Video"
                                    id="video"
                                    data-ready="true"
                                ></iframe>
                                <script src="https://player.vimeo.com/api/player.js" defer></script>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
