import { Link } from 'react-router-dom';
import { Dropdown } from '../../assets/svg/icons';
import List from './NavList';
import LanguageDropdown from './Language';
import Menu from './NavMenu';
import { useState } from 'react';
import { FaGripLines, FaX } from 'react-icons/fa6';
import { FullLogo, HalfLogo } from '../images/logos';

const SideBar = ({isOpen}) => {
	return (
		<div className={`xl:hidden bg-[rgba(44,42,42,0.3)] sm:mt-24 mt-14 fixed h-full inset-0 z-20 overflow-y-auto ${isOpen ? 'block' : 'hidden'}`}>
			<ul className='p-4 md:w-1/4 w-1/2 h-full bg-white opacity-100'>
					<div className='mb-4'>
						<Link to="/">
							<List title="Home" />
						</Link>
					</div>
					<div className='mb-4'>
						<Link to="/about">
							<List title="About Us " />
						</Link>
					</div>
					<div  className="mb-4">
						<div className='flex items-center mb-4'>
							<List title="Our Products" />
							<div className="relative top-[2px] right-[25px]">
								<Dropdown />
							</div>
						</div>
						<ul className='ml-6'>
							<div className='mb-4'>
								<Link to='/products/spices'>
									<List title='Spices' />
								</Link>
							</div>
							<div className='mb-4'>
								<Link to='/products/nuts'>
									<List title='Nuts' />
								</Link>
							</div>
							<div className='mb-4'>
								<Link to='/products/herbs'>
									<List title='Herbs' />
								</Link>
							</div>
							<div className='mb-4'>
								<Link to='/products/seeds'>
									<List title='Seeds' />
								</Link>
							</div>
							<div className='mb-4'>
								<Link to='/products/charcoal'>
									<List title='Charcoal' />
								</Link>
							</div>
						</ul>
					</div>
					<div className='mb-4'>
						<Link to="/faqs">
							<List title="FAQs" />
						</Link>
					</div>
					<div className='mb-4'>
						<Link to="/contact-us">
							<List title="Contact Us" />
						</Link>
					</div>
				</ul>
		</div>
	)
}

export default function NavBar() {
	const [ isDropdownOpen, setDropDownOpen] = useState(false);
	const [sidebar, setSidebar] = useState(false)

	const toggleSidebar = () => {
		setSidebar(!sidebar);
	}

	return (
		<nav className="flex justify-between items-center text-sm font-medium fixed top-0 left-0 right-0 bg-white z-10 border-b border-gray-300 shadow-lg">
			<div className='flex justify-between items-center xl:w-8/12'>
				<div className='sm:ml-10 ml-4 xl:hidden'>
					<button onClick={toggleSidebar} className='xl:hidden p-2' >{sidebar ? <FaX size={24} /> : <FaGripLines size={24} />}</button>
				</div>
				<div className="w-16 sm:w-24 xl:mx-12">
					<Link to="/">
						<img src={FullLogo} alt="Logo" height="5" className='hidden sm:block'/>
						<img src={HalfLogo} alt='Logo' className='sm:hidden' />
					</Link>
				</div>
				<ul className={`xl:flex p-0 hidden`}>
					<Link to="/">
						<List title="Home" />
					</Link>
					<Link to="/about">
						<List title="About Us " />
					</Link>
					<div onMouseEnter={() => setDropDownOpen(true)} onMouseLeave={() => setDropDownOpen(false)} className="relative">
						<div className='flex items-center'>
							<List title="Our Products" />
							<div className="relative top-[2px] right-[25px]">
								<Dropdown />
							</div>
						</div>
						{isDropdownOpen &&  (
							<Menu />
						)}
					</div>
					<Link to="/faqs">
						<List title="FAQs" />
					</Link>
					<Link to="/contact-us">
						<List title="Contact Us" />
					</Link>
				</ul>
				<SideBar isOpen={sidebar} />
			</div>
			<div className='flex p-0'>
				<Link to="https://wa.me/+2348065680616">
					<button className="sm:w-32 w-24 h-10 border-solid border-gray-100 rounded-lg p-2  text-white text-base font-bold" style={{backgroundColor: '#0145ca', transition: "background-color 0.3s ease"}} onMouseOver={(e) => {e.target.style.backgroundColor = "#02338f"}} onMouseOut={(e) => (e.target.style.backgroundColor = '#0145ca')}>
						Let's talk
					</button>
				</Link>
				<LanguageDropdown />
			</div>
		</nav>
	);
}
