import React from 'react';
import WhyUs from '../../components/about/WhyUs';
import HomeSub from '../../components/home/HomeSub';
import Review from '../../components/reviews/Reviews';
import Service from '../../components/services/Service';
import Footer from '../../components/footer/Footer';
import Partners from '../../components/Partners/Partners';
import NavBar from '../../components/navbar/Navbar';

const Home = () => {
	return (
		<>
			<div className="sm:mx-12 mx-6 w-auto mt-4 overflow-hidden">
				<NavBar />
				<Partners />
				<WhyUs />
				<HomeSub />
				<Review />
				<Service />
			</div>
			<Footer />
		</>
	);
};

export default Home;