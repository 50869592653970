import HomeProductsCarousel from "./HomeProductsCarousel";

export default function HomeSub() {
	return (
		<section className="relative xl:mt-[10rem] lg:mt-20 mt-10">
			<div className="lg:w-1/2 sm:w-3/4 w-[95%] mx-auto text-center mb-16">
				<p className="font-semibold text-4xl mb-8 text-deep-blue">
					Learn more about<br className="xl:block hidden" /> our products.
				</p>
				<p className="font-medium text-sm">
					Explore the wide variety of products supplied by Iphecho. From basic grains to dried herbs, our stock meets a wide range of culinary and commercial requirements. Learn more about our goods and discover exactly what you need to improve your meals and businesses.
				</p>
			</div>
				
			<HomeProductsCarousel />
		</section>
	);
}
