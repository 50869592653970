import { Link } from "react-router-dom";

export const ProductCard = ({ title, details }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="mt-10 px-10">
        <h3 className="text-deep-blue sm:text-3xl text-2xl font-bold uppercase mb-5">{title}</h3>
            <ul className="list-disc text-sm my-4">
              {details.map(({ key, value }, index) => (
                <li key={index} className="mb-3">
                  <strong>{key}:</strong> {value}
                </li>
              ))}
            </ul>
        <Link to={'https://wa.me/+2348065680616'}>
          <button className="my-5 rounded-lg px-3 py-2 bg-light-blue hover:bg-deep-blue text-white text-base font-bold">
              INSTANT CHAT
          </button>
        </Link>
      </div>
    </div>
  );
};
