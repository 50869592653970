export default function ReviewCard({
	photo,
	text,
	client,
	job,
	rating1,
	rating2,
	rating3,
	rating4,
	rating5,
	background
}) {
	return (
		<div className="border-gray-100 bg-light-white border-solid border rounded-3xl p-4 mx-5 mt-8 sm:mt-8 sm:w-auto h-auto hover:cursor-pointer hover:border-none hover:shadow-2xl">
			<img src={photo} alt="" className="rounded-full w-16 h-16 sm:w-[4rem] sm:h-[4rem] mb-4 -mt-8 sm:-mt-12" />

			<div className="mb-4 sm:mb-[2rem] text-sm">{text}</div>
			<hr className="mb-4" />
			<div className="flex flex-col lg:flex-row justify-between lg:items-center">
				<div className="mb-2 sm:mb-0">
					<p className="text-deep-blue font-DemiBold">{client}</p>
					<p className="text-sm text-gray-500">{job}</p>
				</div>
				<ul className="flex items-center">
					<li className="mr-1">{rating1}</li>
					<li className="mr-1">{rating2}</li>
					<li className="mr-1">{rating3}</li>
					<li className="mr-1">{rating4}</li>
					<li>{rating5}</li>
				</ul>
			</div>
		</div>
	);
}

