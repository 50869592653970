import React, { useState, useRef, useEffect } from 'react';
import { Minus, Plus } from '../../assets/svg/icons';

export default function FaqsItem({ question, answer, isOpen, onToggleAccordion }) {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    onToggleAccordion();
  };

  useEffect(() => {
    if (isOpen) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [isOpen]);

  return (
    <section
      className={`${
        isOpen ? 'bg-light-white shadow-2xl' : ''
      } rounded-3xl mt-4 p-8 align-left w-[100%] border border-orange-100 cursor-pointer`}
      onClick={toggleAccordion}
    >
      <div className="flex justify-between items-center cursor-pointer mb-4">
        <p className="font-semibold text-light-orange justify-center">{question}</p>
        {isOpen ? <Minus /> : <Plus />}
      </div>
      <div
        ref={contentRef}
        className="text-sm overflow-hidden transition-height duration-500 ease-in-out"
        style={{ height: `${contentHeight}px` }}
      >
        <p className='text-gray-700'>{answer}</p>
      </div>
    </section>
  );
}
