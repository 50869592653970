import { Cashew, CashewKernel, Cassia, Charcoal, Ginger, Pepper, Sesame, SoyaBeans, StoneFlower, Tigernut, Tumeric, Zobo } from '../../components/images/images'
import {ProductGroup} from '../../components/product/ProductGroup'
import products from  '../../utils/products.json'

const spices = [
  {photo: Pepper, title: 'dried chilli pepper'},
  {photo: Ginger, title: 'dried split ginger'},
  {photo: Tumeric, title: 'tumeric'}
]

const nuts = [
  {photo: Tigernut, title: 'tiger nuts'},
  {photo: Cashew, title: 'in-shell cashew nuts'},
  {photo: CashewKernel, title: 'cashew kernel'}
]

const herbs = [
  {photo: Zobo, title: 'dried hibiscus flower'},
  {photo: StoneFlower, title: 'dried stone flower'}
]

const charcoal = [
  {photo: Charcoal, title: 'hardwood charcoal'}
]

const seeds = [
  {photo: Sesame, title: 'sesame seeds'},
  {photo: Cassia, title: 'cassia torra seeds'},
  {photo: SoyaBeans, title: 'soya beans'}
]

export const Spices = () => {
  return <ProductGroup groupName='spices' groupData={spices} products={products}/>
}

export const Herbs = () => {
  return <ProductGroup groupName='herbs' groupData={herbs} products={products} />
}

export const CharcoalPage = () => {
  return <ProductGroup groupName='charcoal' groupData={charcoal} products={products} />
}

export const Seeds = () => {
  return <ProductGroup groupName='seeds' groupData={seeds} products={products} />
}

export const Nuts = () => {
  return <ProductGroup groupName='nuts' groupData={nuts} products={products} />
}