import { Link } from 'react-router-dom';
import FooterList from './FooterList';
import FooterProduct from './FooterProduct';
import { Charcoal, Sesame, Zobo } from '../images/images';
import { FaEnvelope, FaFacebookF, FaInstagram, FaLocationDot, FaMessage, FaMobileScreenButton, FaYoutube } from 'react-icons/fa6';
import { FullLogo, HalfLogo } from '../images/logos';

export default function Footer() {
	return (
		<div className='bg-gray-900'>
			{/* Top part */}
			<div className=''>
				<div className='sm:px-12 px-4 py-8 grid lg:grid-cols-3 lg:gap-4 gap-2 w-full text-white'>
					<div className='lg:col-span-2'>
						<div className='grid md:grid-cols-6 sm:grid-cols-2 md:gap-2 gap-y-2 gay-x-1'>
							<div className='md:col-span-2'>
								<h3 className='text-lg font-bold'>Contact</h3>
								<ul>
									<li className='my-2 flex items-center'>
										<FaLocationDot /> 
										<p className='ml-1'> Corporate: POWA shopping Center, Agodi Police Station, Ibadan, Nigeria</p>
									</li>
									<li className='my-2 flex items-center'>
										<FaEnvelope /> 
										<p className='ml-1'>
											<a href="mailto:ifeanyiobietoh@gmail.com">Email: <span className=' hover:text-light-orange hover:cursor-pointer'>ifeanyiobietoh@gmail.com </span> </a>
										</p>
									</li>
									<li className='my-2 flex items-center'>
										<FaMobileScreenButton /> 
										<p className='ml-1'>
											<a href="https://wa.me/2348065680616" target="_blank" rel="noopenner noreferrer">Phone: <span className='hover:text-light-orange hover:cursor-pointer'>+2348065680616</span> </a>
										</p>
									</li>
								</ul>
							</div>
							<div>
								<h3 className='text-lg font-bold'>Company</h3>
								<ul>
									<Link to='/'>
										<FooterList title='Home' />
									</Link>
									<Link to='/about'>
										<FooterList title='About' />
									</Link>
									<Link to='/products/spices'>
											<FooterList title='Products' />
										</Link>
									</ul>
							</div>
							<div>
									<h3 className='text-lg font-bold'>Support</h3>
									<ul>
										<Link to='/contact-us'>
											<FooterList title='Contact Us' />
										</Link>
										<Link to='/faqs'>
											<FooterList title='FAQs' />
										</Link>
									</ul>
							</div>
							<div className='md:col-span-2'>
								<h3 className='text-lg font-bold'>Featured Products</h3>
								<ul>
									<FooterProduct title='Hardwood Charcoal' to='/products/charcoal' imageUrl={Charcoal} />
									<FooterProduct title='Sesame Seed' to='/products/seeds' imageUrl={Sesame} />
									<FooterProduct title='Dried Hibiscus Flower' to='/products/herbs' imageUrl={Zobo} />
								</ul>
							</div>
						</div>
					</div>
					<div className="w-full">
						<h3 className="text-lg font-bold mb-2">Subscribe to our newsletter.</h3>
						<p className="mb-4">Receive the latest news about us to be updated on our activities.</p>
						<form className="flex">
							<input name="email" type="email" placeholder="Enter your email address" className="p-2 border border-solid border-gray-300 rounded-l-md focus:outline-none text-black" />
							<button type="submit" className="bg-light-orange text-deep-blue font-semibold sm:px-4 px-2 py-2 rounded-r-md transition duration-300 hover:bg-deep-orange">
								Subscribe
							</button>
						</form>
					</div>
				</div>
			</div>
			{/* Bottom part */}
			<div className="flex flex-wrap items-center justify-between sm:py-1 sm:mx-12 mx-4">
				<div className="flex justify-center sm:w-24 w-16 sm:space-x-1 py-1 sm:py-0 items-center bg-white rounded-full mb-5">
					<Link to="/">
						<img src={FullLogo} alt="Logo" height="5" className='sm:block hidden' />
						<img src={HalfLogo} alt='Logo' className='sm:hidden' />
					</Link>
				</div>
				<p className='text-white'>&copy; 2024 Iphecho Limited. All rights reserved.</p>
				<div className="flex space-x-4 mt-4 sm:mt-0">
					<Link to='https://www.facebook.com/profile.php?id=100080147388207&mibextid=ZbWKwL' className="rounded-full bg-light-blue hover:bg-deep-blue p-2 mb-4 text-white"><FaFacebookF /></Link>
					<Link to='https://www.instagram.com/_iphecho?igsh=MzNlNGNkZWQ4Mg==' className="rounded-full bg-light-blue hover:bg-deep-blue p-2 mb-4 text-white"><FaInstagram /></Link>
					{/* <Link to='/' className="rounded-full bg-light-blue hover:bg-deep-blue p-2 mb-4 text-white"><FaXTwitter /></Link> */}
					<Link to='https://www.youtube.com/@iphechonigerialimited1353' className="rounded-full bg-light-blue hover:bg-deep-blue p-2 mb-4 text-white"><FaYoutube /></Link>
				</div>
			</div>
		</div>
	);
}
