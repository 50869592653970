import React from 'react'
import {countries} from 'countries-list'

const Input = ({type, name, id, labelText, holder}) => {
  return (
    <div className='my-5'>
        <label htmlFor={name} className='block text-sm font-medium leading-6 text-deep-blue capitalize'>{labelText}</label>
        <div className='mt-2'>
            <input type={type} name={name} id={id} className='block w-full rounded-lg border-0 py-1.5 pl-5 pr-10 text-gray-900 ring-1 ring-gray-300 ring-inset placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-offset-black sm:text-sm sm:leading-6' placeholder={holder} required />
        </div>
    </div>
  )
}

const CountrySelection = () => {
    const countryOptions = Object.entries(countries).map(
        ([countryCode, countryData]) => (
            <option key={countryCode} value={countryCode}>{countryData.name}</option>
        )
    )
    return (
        <div className='my-5'>
            <label htmlFor="country" className='block text-sm font-medium leading-6 text-deep-blue'>Country</label>
            <div className='mt-2'>
                <select name="country" id="country" className='block w-full rounded-lg border-0 py-1.5 pl-5 pr-10 text-gray-900 ring-1 ring-gray-300 ring-inset focus:ring-2 focus:ring-inset focus:ring-offset-black sm:text-sm sm:leading-6'>
                    {countryOptions}
                </select>
            </div>
        </div>
    )
}

const Select = () => {
    return (
        <div className='my-5'>
            <label htmlFor="topic" className='block text-sm font-medium leading-6 text-deep-blue'>Reason For Contacting Us</label>
            <div className='mt-2'>
                <select name="topic" id="topic" className='block w-full rounded-lg border-0 py-1.5 pl-5 pr-10 text-gray-900 ring-1 ring-gray-300 ring-inset focus:ring-2 focus:ring-inset focus:ring-offset-black sm:text-sm sm:leading-6'>
                    <option value="order">Place an order</option>
                    <option value="complain">Make a complain</option>
                    <option value="feedback">Give us a feedback</option>
                </select>
            </div>
        </div>
    )
}

const Message = ({label, holder}) => {
    return (
        <div className='my-5'>
            <label htmlFor="message" className='block text-sm font-medium leading-6 text-deep-blue'>{label}</label>
            <div className='mt-2'>
                <textarea name="message" id="message" rows="6" className='block w-full rounded-lg border-0 py-1.5 pl-5 pr-10 text-gray-900 ring-1 ring-gray-300 ring-inset focus:ring-2 focus:ring-inset focus:ring-offset-black sm:text-sm sm:leading-6' placeholder={holder}></textarea>
            </div>
        </div>
    )
}

export {
    Input,
    Select,
    CountrySelection,
    Message
}