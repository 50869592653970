import React, { useState } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import HomeSubCard from "./HomeSubCard";
import { Cashew, Charcoal, Ginger, Pepper, Sesame, SoyaBeans, Tigernut, Tumeric, Zobo } from "../images/images";

const productsDetails = [
    {
        productName: 'Cashew Nuts',
        details: [
            {key: 'Origin', value: 'Nigeria(Ogbomosho)'}, {key: 'Out-turn', value: '-kor 52lbs+'}, {key: 'Moisture', value: '10 max'}, {key: 'Nut Count', value: '175 - 200 max'}, {key: 'Admixture', value: '2% max'}, {key: 'Packaging', value: '80kg jute bags'}, {key: 'Quantity', value: '1000MT - 10,000MT monthly'}, {key: 'Payment Term', value: 'LC or TT'}, {key: 'Other Names', value: 'Raw Cashew Nut . In-shell Cashew Nut'} 
        ],
        image: Cashew
    },
    {
        productName: 'Tiger Nuts',
        details: [
            {key: 'Origin', value: 'Nigeria'}, {key: 'Size', value: '4mm-15mm'}, {key: 'Moisture', value: '<10%'}, {key: 'Colour', value: 'Brown'}, {key: 'Features', value: 'Dry, unwashed, vented >97%'}, {key: 'Packaging', value: '50kg pp bags/buyer\'s request'}, {key: 'Payment term', value: '100% confirm irrevocable LC at sight/TT'}, {key: 'Moq', value: '18 metric tons'}, {key: 'Port', value: 'Lagos'}, {key: 'Loading capacity', value: '20 feet container/18 metric tons'}, {key: 'Other names', value: 'Cyperus esculentus, chufa, atadwe, yellow nutsedge, earth almond'}
        ],
        image: Tigernut
    },
    {
        productName: 'Hardwood Charcoal',
        details: [
            {key: 'Grade', value: 'Pure Ayin'}, {key: 'Origin', value: 'Nigeria'}, {key: 'Type', value: 'Natural hardwood'}, {key: 'Wood type', value: '100% beech wood'}, {key: 'Lump size', value: '7cm+'}, {key: 'Fixed carbon', value: '>75%'}, {key: 'Volatile matters', value: '15.5%'}, {key: 'Ash content', value: '<5%'}, {key: 'Moisture content', value: '<5%'}, {key: 'Heating level', value: 'Kcal/Kg 6800'}, {key: 'Burning time', value: '700sec/cm3'} 
        ],
        image: Charcoal
    },
    {
        productName: 'Dried Split Ginger',
        details: [
            {key: 'Origin', value: 'Nigeria'}, {key: 'Available type', value: 'ASTA, FAQ'}, {key: 'Cultivation', value: 'Organic'}, {key: 'Colour', value: 'Natural'}, {key: 'Process', value: 'Cleaned, sliced and sun-dried'}, {key: 'Style', value: 'Spicy flavour, long shelf-life'}, {key: 'Moisture', value: '<8%'}, {key: 'Oil content', value: '<2%'}, {key: 'Impurities', value: '<2%'}, {key: 'Packaging', value: '40kg new sound PP bags'}, {key: 'Payment terms', value: 'Confirmed LC/Telegraphic Transfer(TT)'},{key: 'Other names', value: 'Zingiber officinale • Имбирь • 姜 • Gingembre • 생강 • Jengibre • Ginger'}
        ],
        image: Ginger
    },
    {
        productName: 'Dried Chilli Pepper',
        details: [
            {key: 'Processing type', value: 'Raw'}, {key: 'Origin', value: 'Nigeria'}, {key: 'Cultivation', value: 'Organic'}, {key: 'Type', value: 'Chilli fingers'}, {key: 'Style', value: 'Dried'}, {key: 'Length', value: '5-7cm'}, {key: 'Moisture', value: '10% max'}, {key: 'Impurities', value: '2% max'}, {key: 'Drying process', value: 'Sun-dried'}, {key: 'Packaging', value: '25/50kg bags or buyer\'s request'}, {key: 'Shipment', value: 'In container'}, {key: 'MOQ', value: '10MT (1 x 40 ft FCL)'}, {key: 'Loading port', value: 'Lagos'}, {key: 'Payment', value: '100% irrevocable confirmed LC/TT'}
        ],
        image: Pepper
    },
    {
        productName: 'Sesame Seeds',
        details: [
            {key: 'Crop', value: 'New harvest'}, {key: 'Origin', value: 'Nigeria)'}, {key: 'Cultivation', value: 'Organic'}, {key: 'Purity', value: '99%'}, {key: 'Moisture', value: '5-6% max'}, {key: 'FFA', value: '2%'}, {key: 'Oil content', value: '48% min.'}, {key: 'Packaging', value: '50kg new PP bags'}, {key: 'Payment Term', value: 'Confirmed LC/TT'}, {key: 'Other names', value: 'Sesamum indicum • Семена кунжута • 芝麻 • Graines de sésame • 참기름 • Semillas de sésamo (ajonjolí) • Sesame Seed'} 
        ],
        image: Sesame
    },
    {
        productName: 'Dried Hibiscus Flower',
        details: [
            {key: 'Origin', value: 'Nigeria'}, {key: 'Processing type', value: 'Raw'}, {key: 'Cultivation', value: 'Organic'}, {key: 'Grade', value: 'Full flower'}, {key: 'Style', value: 'Sun-dried'}, {key: 'Moisture', value: '10% max'}, {key: 'Total ash', value: '10% max'}, {key: 'Full flower', value: '90% min'}, {key: 'Acid insoluble ash', value: '1.5% max'}, {key: 'Extract content', value: '41% max'}, {key: 'Packaging', value: '25kg bags'}, {key: 'Payment', value: 'Confirmed LC/TT'}, {key: 'Other names', value: 'Hibiscus rosa sinensis • Гибискус • 芙蓉花 • 히비스커스 • Jamaica • Hibiscus'}
        ],
        image: Zobo
    },
    {
        productName: 'Soyabeans',
        details: [
            {key: 'Origin', value: 'Nigeria'}, {key: 'Processing type', value: 'Raw'}, {key: 'Cultivation', value: 'Organic'}, {key: 'Grade', value: 'Full flower'}, {key: 'Style', value: 'Sun-dried'}, {key: 'Moisture', value: '10% max'}, {key: 'Total ash', value: '10% max'}, {key: 'Full flower', value: '90% min'}, {key: 'Acid insoluble ash', value: '1.5% max'}, {key: 'Extract content', value: '41% max'}, {key: 'Packaging', value: '25kg bags'}, {key: 'Payment', value: 'Confirmed LC/TT'}, {key: 'Other names', value: 'Hibiscus rosa sinensis • Гибискус • 芙蓉花 • 히비스커스 • Jamaica • Hibiscus'}
        ],
        image: SoyaBeans
    },
    {
        productName: 'Tumeric',
        details: [
            {key: 'Origin', value: 'Nigeria'}, {key: 'Processing type', value: 'Raw'}, {key: 'Cultivation', value: 'Organic'}, {key: 'Grade', value: 'Full flower'}, {key: 'Style', value: 'Sun-dried'}, {key: 'Moisture', value: '10% max'}, {key: 'Total ash', value: '10% max'}, {key: 'Full flower', value: '90% min'}, {key: 'Acid insoluble ash', value: '1.5% max'}, {key: 'Extract content', value: '41% max'}, {key: 'Packaging', value: '25kg bags'}, {key: 'Payment', value: 'Confirmed LC/TT'}, {key: 'Other names', value: 'Hibiscus rosa sinensis • Гибискус • 芙蓉花 • 히비스커스 • Jamaica • Hibiscus'}
        ],
        image: Tumeric
    }
]

const customDot = {
    width: '10px',
    height: '10px',
    margin: '20px 0px 0px',
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: '#d06821',
    borderRadius: '50%',
    backgroundColor: '#fff',
    transition: 'border 0.3s ease',
}

export default function HomeProductsCarousel() {
    const [hoveredDot, setHoveredDot] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        customPaging: function (i) {
            const isHovered = i === hoveredDot;
            const isActive = i === activeSlide;
      
            return (
                <div
                    style={{
                    ...customDot,
                    borderColor: isActive ? '#02338f' : isHovered ? '#02338f' : customDot.borderColor,
                    }}
                    key={i}
                    onMouseEnter={() => setHoveredDot(i)}
                    onMouseLeave={() => setHoveredDot(null)}>
                </div>
            );
        },
        afterChange: (index) => setActiveSlide(index),
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false,
                    infinite: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false,
                    infinite: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false,
                    infinite: true,
                },
            },
        ],
    };

    return (
        <Slider {...settings}>
            {productsDetails.map((product, index) => 
                <div key={index} className="px-4">
                    <HomeSubCard 
                        backgroundImage={product.image} 
                        text={product.productName} 
                        text2={product.details} 
                    />
                </div>
            )}
        </Slider>
    )
}