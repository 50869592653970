import React from 'react'
import { Bags } from '../images/images'
import { FullLogo } from '../images/logos'
import { FaBuildingColumns, FaHandHoldingDollar, FaTruckFast } from 'react-icons/fa6'

const GridList = ({ icon, heading, body }) => {
    return (
        <div className='text-white w-11/12 max-w-xs mx-auto md:max-w-sm lg:max-w-md p-4'>
            <div className='w-12 mx-auto p-1 text-3xl mb-1'>{icon}</div>
            <h5 className="text-xl md:text-2xl font-bold mb-2">{heading}</h5>
            <p className='text-sm md:text-base'>{body}</p>
        </div>
    );
};

const Approach = () => {
    return (
        <div className='mt-24 mb-10'>
            <div className='relative text-center text-white min-h-max'>

                <div className='absolute inset-0'>
                    <img src={Bags} alt="" className='w-full h-full object-cover object-center' />
                    <div className='absolute inset-0 bg-gradient-to-b from-transparent to-black'></div>
                </div>

                <div className='relative mx-auto flex flex-col justify-center h-full'>
                    <div className='w-20 mx-auto mb-4 lg:mb-6'>
                        <img src={FullLogo} alt="Full Logo" />
                    </div>
                    <h3 className='text-3xl sm:text-4xl lg:text-6xl font-bold text-center w-11/12 sm:w-8/12 lg:w-6/12 mx-auto mb-12 lg:mb-24'>How did we achieve this?</h3>
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-5 w-full max-w-screen-lg mx-auto p-4'>
                        <GridList icon={<FaBuildingColumns />} heading='Premium Quality' body='From start to finish, we ensure excellence at all stages so that each product preserves its outstanding flavor, freshness, and nutritional content.' />
                        <GridList icon={<FaTruckFast />} heading="Swift Delivery" body="Our simplified transportation system and key partnerships allow us to accurately complete and speed up your deliveries." />
                        <GridList icon={<FaHandHoldingDollar />} heading="Cost Effective" body="We can offer affordable costs while maintaining quality thanks to effective processes and useful partnerships." />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Approach