import React from 'react';
import Footer from '../../components/footer/Footer';
import { ContactForm } from '../../components/contact/ContactForm';
import Locator from '../../components/contact/Locator';
import NavBar from '../../components/navbar/Navbar';

export const ContactUs = () => {
  return (
    <>
        <div className='sm:mx-12 mx-6 w-auto mt-4 overflow-hidden'>
            <NavBar />
            <ContactForm />
            <Locator />
        </div>
        <Footer />
    </>
  )
}
