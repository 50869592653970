import { Link } from "react-router-dom";

export default function HomeSubCard({ text, text2 = [], children, backgroundImage }) {
  return (
    <div className="relative group">
      <div className="w-full rounded-3xl p-4 mx-auto sm:mx-3 hover:cursor-pointer hover:shadow-2xl" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '400px'}}>
        {/* Overlay */}
        <div className="absolute inset-0 sm:left-3 flex items-center justify-center bg-black opacity-50 rounded-3xl w-full h-full">
          <h3 className="text-white text-3xl font-bold">{text}</h3>
        </div>

        {/* Hovered Overlay */}
        <div className="absolute inset-0 sm:left-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-deep-blue rounded-3xl grid items-center justify-center w-full h-full text-white">
          <div className=" w-11/12 mx-auto">
            <div className=" text-light-orange">
              <p className="lg:text-base text-lg font-bold">{text}</p>
            </div>

            <div className="lg:text-[0.7rem] text-xs">
              <ul className="list-disc list-inside">
                {text2.map(({ key, value }, index) => (
                  <li key={index} className="text-start">
                    <strong>{key}:</strong> {value}
                  </li>
                ))}
              </ul>
              {children}
            </div>

            <Link to="/">
              <button className="w-40 h-10 mt-2 border-solid border-gray-100 rounded-lg p-2 bg-light-orange text-light-blue text-base font-bold">
                REQUEST QUOTE
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
